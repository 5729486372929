// src/components/MobileRedirect.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const MobileRedirect = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        padding: '20px',
        textAlign: 'center',
        color: 'white',
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '90%',
        margin: '0 auto'
      }}
    >
      {/* Icono animado de computadora */}
      <motion.div
        animate={{ 
          scale: [1, 1.1, 1],
          rotate: [0, 5, -5, 0]
        }}
        transition={{ 
          duration: 2,
          repeat: Infinity,
          repeatType: "reverse"
        }}
        style={{
          fontSize: '80px',
          marginBottom: '30px'
        }}
      >
        💻
      </motion.div>

      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        style={{
          fontSize: '28px',
          fontWeight: 'bold',
          marginBottom: '20px',
          background: 'linear-gradient(45deg, #4F46E5, #06B6D4)',
          padding: '10px 20px',
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}
      >
        ¡Ups! Acceso solo desde ordenador
      </motion.h2>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          padding: '20px',
          borderRadius: '15px',
          marginBottom: '30px',
          maxWidth: '400px'
        }}
      >
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '15px' }}>
          Lo sentimos, nuestra demo requiere una pantalla más grande para mostrarte todas sus funcionalidades. 🎯
        </p>
        <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
          Por favor, accede desde un ordenador para disfrutar de la experiencia completa. ✨
        </p>
      </motion.div>

      {/* Indicador de dispositivos */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.9 }}
        style={{
          display: 'flex',
          gap: '20px',
          marginBottom: '30px'
        }}
      >
        <div style={{ opacity: 0.3 }}>
          📱 Móvil
          <div style={{ color: '#EF4444', fontSize: '12px' }}>No disponible</div>
        </div>
        <div style={{ opacity: 0.3 }}>
          📱 Tablet
          <div style={{ color: '#EF4444', fontSize: '12px' }}>No disponible</div>
        </div>
        <div style={{ opacity: 1 }}>
          💻 Ordenador
          <div style={{ color: '#10B981', fontSize: '12px' }}>Disponible</div>
        </div>
      </motion.div>

      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Link 
          to="/"
          style={{
            display: 'inline-block',
            padding: '12px 24px',
            backgroundColor: '#4F46E5',
            color: 'white',
            textDecoration: 'none',
            borderRadius: '8px',
            fontWeight: 'bold',
            transition: 'all 0.3s ease',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          }}
        >
          ← Volver al inicio
        </Link>
      </motion.div>

      {/* Indicador de desplazamiento */}
      <motion.div
        animate={{ 
          y: [0, 10, 0],
        }}
        transition={{ 
          duration: 1.5,
          repeat: Infinity,
          repeatType: "reverse"
        }}
        style={{
          position: 'absolute',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: '24px',
          opacity: 0.7
        }}
      >
        ↑
      </motion.div>
    </motion.div>
  );
};

export default MobileRedirect;