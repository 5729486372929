import React, { useState, useEffect } from 'react';
import { Container, Flex, Box, Text, Link, Button } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import Logo from '../../assets/images/logo.png';
import footerimage from '../../assets/images/footer.jpg'
import '../../styles/Footer.css'
import { Link as PageLink, useLocation } from 'react-router-dom';

const FooterColumn = ({ title, links, isMobile, isTablet }) => (
  <Box style={{
    marginBottom: '24px',
    textAlign: isMobile ? 'center' : 'left',
    width: isTablet ? '100%' : 'auto'
  }}>
    <Text style={{
      fontWeight: 'bold',
      marginBottom: isMobile ? '20px' : isTablet ? '24px' : '32px',
      display: 'block',
      color: 'white',
      letterSpacing: '0.5px',
      fontSize: isMobile ? '1.1rem' : isTablet ? '1.2rem' : '1.333rem'
    }}>{title}</Text>
    {links.map((link, index) => (
      <Text key={index} size="2" style={{
        display: 'block',
        marginBottom: isMobile ? '16px' : isTablet ? '18px' : '20px',
        lineHeight: '1.8'
      }}>
        <Link href="#" style={{
          color: '#999',
          textDecoration: 'none',
          fontSize: isMobile ? '0.9rem' : isTablet ? '0.95rem' : '1rem'
        }}>
          {link}
        </Link>
      </Text>
    ))}
  </Box>
);

const Footer = () => {
  const { pathname } = useLocation()
  const handleButtonClick = () => {
    if (pathname === '/pricing') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: typeof window !== 'undefined' ? window.innerWidth : 0,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  };

  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const isTablet = width <= 1024 && width > 768;



  return (
    <>
      <Box style={{
        position: 'relative',
        paddingTop: isMobile ? '10rem' : isTablet ? '12rem' : '15rem',
        backgroundColor: 'white'
      }}>
        <Box style={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: isMobile ? '95%' : isTablet ? '92%' : '90%',
          maxWidth: '1200px',
          backgroundColor: '#2D2D3A',
          borderRadius: '12px',
          padding: isMobile ? '24px' : isTablet ? '32px' : '48px',
          color: 'white',
          textAlign: 'center',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
          marginTop: isMobile ? '1rem' : '2rem',
          zIndex: 2,
          background: `
            linear-gradient(
              rgba(45, 45, 58, 0.95), 
              rgba(45, 45, 58, 0.95)
            ),
            url(${footerimage})
          `,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          overflow: 'hidden',
        }}>
          <Text style={{
            fontWeight: 'bold',
            marginBottom: isMobile ? '20px' : isTablet ? '24px' : '32px',
            lineHeight: '1.4',
            fontSize: isMobile ? '2rem' : isTablet ? '3rem' : '4.209rem'
          }}>
            ¿Listo para transformar la forma en que gestionas tu negocio en Mercado Libre? <br />
          </Text>
          <Text style={{
            marginBottom: isMobile ? '24px' : '40px',
            lineHeight: '2',
            color: '#999',
            fontSize: isMobile ? '1rem' : '1.333rem'
          }}>
            Conecta tus datos automáticamente con Google Sheets y toma decisiones inteligentes basadas en información en tiempo real. <br />
            ✅ Ahorra tiempo y reduce costos operativos. <br />
            ✅ Optimiza tu inventario y maximiza tus ganancias. <br />
            ✅ Simplifica la gestión de tu negocio como nunca antes. <br />
          </Text>
          <Flex
            direction={isMobile ? 'column' : 'row'}
            justify="center"
            gap={isMobile ? "2" : "4"}
            style={{
              marginTop: '2rem',
              marginBottom: isMobile ? '2rem' : '0',
              position: 'relative',
              zIndex: 1
            }}
          >
            {
              pathname !== '/pricing' ? (
                <PageLink to="/pricing" style={{ 'textDecoration': 'none' }}>
                  <Button size="4" className="custom-button-footer">
                    ¡Comienza gratis ahora!
                    <CursorArrowIcon style={{ marginLeft: '8px' }} />
                  </Button>
                </PageLink>
              ) : (
                <Button size="4" className="custom-button-footer" onClick={handleButtonClick}>
                  ¡Comienza gratis ahora!
                  <CursorArrowIcon style={{ marginLeft: '8px' }} />
                </Button>
              )
            }
            <PageLink to={'/demo'} style={{ textDecoration: 'none' }}>
              <Button size="4" variant="outline-footer" className="outline-button-footer">
                Probar demo
              </Button>
            </PageLink>
          </Flex>
        </Box>


        <Box style={{
          backgroundColor: '#1E293B',
          color: 'white',
          paddingTop: isMobile ? '300px' : '220px',
          paddingBottom: '40px',
          position: 'relative',
          zIndex: 1,
          marginTop: isMobile ? '4rem' : '7rem',
        }}>
          <Container size="3">
            <Flex
              direction={isMobile ? 'column' : 'row'}
              justify="between"
              wrap="wrap"
              gap={isMobile ? "4" : isTablet ? "3" : "6"}
              style={{
                paddingTop: isMobile ? '2rem' : isTablet ? '10rem' : '13rem',
                textAlign: isMobile ? 'center' : 'left',
                maxWidth: '100%',
                position: 'relative',
              }}
            >
              <Box style={{
                marginBottom: isMobile ? '40px' : '24px',
                maxWidth: isMobile ? '100%' : isTablet ? '45%' : '300px',
                marginTop: width < 460 ? '130px' : isMobile ? '60px' : '40px',
                flexBasis: isTablet ? '45%' : 'auto',
                position: isMobile ? 'relative' : 'static',
                zIndex: isMobile ? '2' : 'auto',
              }}>
                <img
                  src={Logo}
                  alt="SheetSellerApp Logo"
                  style={{
                    height: '40px',
                    marginBottom: '16px',
                    display: 'block',
                    margin: isMobile ? '0 auto 24px' : '0 0 16px',
                  }}
                />
                <Text style={{
                  display: 'block',
                  lineHeight: '1.5',
                  color: '#999',
                  fontSize: isMobile ? '0.9rem' : '1rem',
                  padding: isMobile ? '0 20px' : '0',
                  maxWidth: isMobile ? '100%' : 'none',
                }}>
                  Conecta Mercado Libre y Google Sheets para mantener tus datos sincronizados en tus hojas de cálculo 24/7.
                </Text>
              </Box>
              {!isMobile && (
                <>
                  <Box style={{ flexBasis: isTablet ? '45%' : 'auto' }}>
                    <FooterColumn
                      title="Aprende"
                      links={['Guía de inicio', 'Documentación', 'Tutoriales', 'Preguntas frecuentes']}
                      isMobile={isMobile}
                      isTablet={isTablet}
                    />
                  </Box>

                  <Box style={{ flexBasis: isTablet ? '45%' : 'auto' }}>
                    <FooterColumn
                      title="Recursos"
                      links={['Blog', 'Plantillas', 'Calculadora de ROI', 'Centro de ayuda']}
                      isMobile={isMobile}
                      isTablet={isTablet}
                    />
                  </Box>

                  <Box style={{ flexBasis: isTablet ? '45%' : 'auto' }}>
                    <FooterColumn
                      title="Compañía"
                      links={['Acerca de nosotros', 'Contacto', 'Política de privacidad', 'Términos de servicio']}
                      isMobile={isMobile}
                      isTablet={isTablet}
                    />
                  </Box>
                </>
              )}
            </Flex>
            <Text size="1" style={{
              marginTop: isMobile ? '32px' : '48px',
              textAlign: 'center',
              color: '#999',
              display: 'block',
              fontSize: isMobile ? '0.875rem' : '1rem'
            }}>
              © 2024 SheetSeller. Todos los derechos reservados.
            </Text>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Footer;