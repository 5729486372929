import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Heading, Flex, Text, Button } from '@radix-ui/themes';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons'
import './css/PricingPlan.css';
import { Link as ScrollLink } from 'react-scroll';

const PlansContainer = styled(Container)`
  padding: ${props => props.isMobile ? '2rem 1rem' : '4rem 0'};
  background-color: #1c1c1c;
`;


const PlanCard = styled.div`
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: ${props => props.isMobile ? '1.5rem' : '2rem'};
  width: ${props => props.isMobile ? '100%' : '300px'};
  transition: transform 0.3s ease;
  margin: ${props => props.isMobile ? '0.5rem 0' : '0'};

  ${props => !props.isMobile && `
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0,0,0,0.2);
    }
  `}
`;



const ExpandButton = styled.button`
  background: none;
  border: none;
  color: #02A724;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 1rem;
`;



const Plan = ({ name, price, features, onSelect, isMobile }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const FeaturesList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      color: #b0b0b0;
      margin: 8px 0;
      padding-left: 20px;
      position: relative;
      font-size: ${isMobile ? '0.9rem' : '1rem'};

      &:before {
        content: "✓";
        position: absolute;
        left: 0;
        color: #02A724;
      }
    }
  `;

  return (
    <PlanCard isMobile={isMobile}>
      <Flex direction="column" gap="2">
        <Heading style={{ 
          color: '#fff', 
          fontSize: isMobile ? '1.4rem' : '1.777rem' 
        }}>
          {name}
        </Heading>
        <Text style={{ 
          color: '#fff', 
          fontSize: isMobile ? '1.4rem' : '1.777rem'
        }}>
          ${price}/mes
        </Text>
        
        <ScrollLink to='PricingCalculator' smooth={true}>
          <Button 
            className="plan-button" 
            size={isMobile ? "2" : "3"} 
            onClick={onSelect}
          >
            Elegir plan
          </Button>
        </ScrollLink>

        {isMobile ? (
          <>
            {isExpanded && (
              <FeaturesList>
                {features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </FeaturesList>
            )}
            <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? (
                <>Ver menos <ChevronUpIcon /></>
              ) : (
                <>Ver detalles <ChevronDownIcon /></>
              )}
            </ExpandButton>
          </>
        ) : (
          <FeaturesList>
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </FeaturesList>
        )}
      </Flex>
    </PlanCard>
  );
};




const PricingPlans = ({ onPlanSelect }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);



  return (
    <PlansContainer size="4" isMobile={isMobile}>
      <Heading 
        align="center" 
        style={{ 
          marginBottom: isMobile ? '2rem' : '5rem', 
          color: '#fff', 
          fontSize: isMobile ? '2rem' : '3.157rem',
          padding: isMobile ? '0 1rem' : '0'
        }}
      >
        Elige el plan que se adapte a tus necesidades
      </Heading>
      <Flex 
        direction={isMobile ? 'column' : 'row'} 
        justify="center" 
        gap={isMobile ? "3" : "6"} 
        wrap={isMobile ? "nowrap" : "wrap"}
      >
      <Plan
        name="Principante"
        price={699}
        features={[
          "Hasta 500 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas en 24 horas.",
          "Acceso al paquete de plantillas básicas.",
          "Prueba gratis de 15 días.",
          "Ideal para vendedores que están comenzando o tienen un volumen bajo de ventas."
        ]}
        onSelect={() => onPlanSelect(500)}
        isMobile={isMobile}
      />
      <Plan
        name="Básico"
        price={999}
        features={[
          "Hasta 1,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas en 24 horas.",
          "Acceso al paquete de plantillas básicas.",
          "Prueba gratis de 15 días.",
          "Perfecto para negocios en crecimiento."
        ]}
        onSelect={() => onPlanSelect(1000)}
        isMobile={isMobile}
      />
      <Plan
        name="Pro"
        price={1999}
        features={[
          "Hasta 6,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas en 12 horas.",
          "Acceso al paquete de plantillas básicas.",
          "Prueba gratis de 15 días.",
          "Ideal para vendedores con un volumen medio-alto de ventas.",
        ]}
        onSelect={() => onPlanSelect(6000)}
        isMobile={isMobile}
      />
      <Plan
        name="Mega"
        price={3999}
        features={[
          "Hasta 15,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas en 12 horas.",
          "Acceso al paquete de plantillas básicas.",
          "Prueba gratis de 15 días.",
          "Perfecto para negocios consolidados con alto volumen de ventas.",
        ]}
        onSelect={() => onPlanSelect(15000)}
        isMobile={isMobile}
      />
      <Plan
        name="Enterprise 30k"
        price={5999}
        features={[
          "Hasta 30,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas de inmediato (1 hora).",
          "Acceso al paquete de plantillas básicas y plantillas premium.",
          "Prueba gratis de 15 días.",
          "Ideal para grandes empresas con necesidades específicas.",
        ]}
        onSelect={() => onPlanSelect(30000)}
        isMobile={isMobile}
      />
      <Plan
        name="Enterprise 50k"
        price={8999}
        features={[
          "Hasta 50,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas de inmediato (1 hora).",
          "Acceso al paquete de plantillas básicas y plantillas premium.",
          "Prueba gratis de 15 días.",
          "Ideal para empresas con operaciones complejas.",
        ]}
        onSelect={() => onPlanSelect(50000)}
        isMobile={isMobile}
      />
      <Plan
        name="Enterprise 70k"
        price={10999}
        features={[
          "Hasta 70,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas de inmediato (1 hora).",
          "Acceso al paquete de plantillas básicas y plantillas premium.",
          "Prueba gratis de 15 días.",
          "Ideal para empresas con alto volumen de ventas y necesidades específicas."
        ]}
        onSelect={() => onPlanSelect(70000)}
        isMobile={isMobile}
      />
      <Plan
        name="Enterprise 100k"
        price={14999}
        features={[
          "Hasta 100,000 órdenes por mes.",
          "Acceso a información en tiempo real.",
          "Integración con múltiples cuentas de Mercado Libre.",
          "Plataforma de administración de permisos (admin/operadores).",
          "Respuesta a consultas técnicas de inmediato (1 hora).",
          "Acceso al paquete de plantillas básicas y plantillas premium.",
          "Prueba gratis de 15 días.",
          "Ideal para empresas líderes en su sector.",
        ]}
        onSelect={() => onPlanSelect(100000)}
        isMobile={isMobile}
      />
    </Flex>
  </PlansContainer>
  )
};

export default PricingPlans;