import * as React from 'react';
import { Container, Heading, Box, Text, Flex } from '@radix-ui/themes';
import { useState, useEffect } from 'react';

const SyncBanner = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <Container 
      size="6" 
      py={isMobile ? "6" : "9"} 
      style={{
        backgroundColor: 'white',
        color: 'white',
        padding: isMobile ? '0 1rem' : undefined
      }}
    >
      <Box style={{
        background: 'linear-gradient(135deg, #50D5B7, #067D68)',
        borderRadius: '12px',
        padding: isMobile ? '2rem 1.5rem' : '4rem',
        marginTop: isMobile ? '2rem' : '3rem',
        color: 'white',
        textAlign: 'center',
        marginBottom: isMobile ? '3rem' : undefined,
      }}>
        <Flex direction="column" align="center">
          <Text 
            size={isMobile ? "6" : "7"} 
            style={{ 
              marginBottom: '0.5rem', 
              color: '#FFD700'
            }}
          >
            ★★★★★
          </Text>
          <Heading 
            style={{ 
              marginBottom: '1rem', 
              fontSize: isMobile ? '2rem' : '3.157rem', 
              lineHeight: isMobile ? '2.5rem' : '4rem',
              padding: isMobile ? '0 0.5rem' : '0'
            }}
          >
            Más de $400M en ventas sincronizadas en Mercado Libre
          </Heading>
          <Text 
            size={isMobile ? "4" : "5"} 
            style={{ 
              fontSize: isMobile ? '1.2rem' : '1.777rem', 
              lineHeight: '1.5',
              padding: isMobile ? '0 0.5rem' : '0'
            }}
          >
            Empresas de todos los tamaños confían en nuestra tecnología para obtener datos precisos y actualizados a través de nuestro complemento de Google Sheets.
          </Text>
        </Flex>
      </Box>
    </Container>
  );
};

export default SyncBanner;