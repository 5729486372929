import React from 'react';
import { Box, Flex, Text, Heading, Container } from '@radix-ui/themes';
import overwhelmed from '../../assets/images/overwhelmed.png';
import '../../styles/OldNewWay.css'

const OldWay = () => {
  const items = [
    "Perder tiempo generando reportes manualmente",
    "Cambiar constantemente entre cuentas y plataformas",
    "Copiar y pegar datos, desperdiciando horas valiosas",
    "Trabajar con información desactualizada",
    "Repetir procesos una y otra vez...",
  ];

  return (
    <Container size="3" className="oldway-container">
      <Box className="oldway-box">
        <Flex className="oldway-flex-container">
          <Box className="oldway-content">
            <Text className='subtitle-way'>
              Cómo funcionan la mayoría de empresas
            </Text>
            <Heading className='title-oldway'>
              La forma antigua
            </Heading>
            <Box as="ul" className="oldway-list">
              {items.map((item, index) => (
                <Flex as="li" key={index} className="oldway-list-item">
                  <Box className="oldway-x-mark">
                    &times;
                  </Box>
                  <Text className='points-ways'>
                    {item}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Box>
          <Box className="oldway-image-container">
            <img src={overwhelmed} alt="La forma antigua" className="oldway-image" />
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default OldWay;