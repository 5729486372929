import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Flex, Button } from '@radix-ui/themes';
import { motion, AnimatePresence } from 'framer-motion';
import expect1 from '../../assets/images/sales.png';
import expect2 from '../../assets/images/stock.png';
import expect3 from '../../assets/images/monthli.png';

const WhatToExpect = () => {
    const [selectedExpectation, setSelectedExpectation] = useState(0);
    const useWindowSize = () => {
        const [windowSize, setWindowSize] = useState({
            width: typeof window !== 'undefined' ? window.innerWidth : 0,
        });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                });
            }

            window.addEventListener('resize', handleResize);
            handleResize();
            
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowSize;
    };

    const { width } = useWindowSize();
    const isMobile = width <= 768;

    const expectations = [
        {
            title: "Control total sobre tus ventas",
            description: "Analiza y comprende el desempeño de tus productos de manera más eficiente. Toma decisiones informadas para maximizar tus resultados.",
            image: expect1
        },
        {
            title: "Gestión inteligente de inventario",
            description: "Lleva un control preciso de tu inventario en tiempo real. Con SheetSeller, siempre sabrás exactamente qué productos tienes disponibles, cuáles necesitan reposición y cómo optimizar tu stock para evitar pérdidas.",
            image: expect2
        },
        {
            title: "Seguimiento de tus ganancias",
            description: "Mantén un control detallado de tus ingresos mensuales y evalúa el rendimiento de tu negocio con datos precisos y actualizados.",
            image: expect3
        }
    ];

    const containerStyle = {
        backgroundColor: '#f8f9fa',
        padding: isMobile ? '3rem 0' : '6rem 0',
        fontFamily: "'Roboto', 'Arial', sans-serif",
    };

    const contentContainerStyle = {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: isMobile ? '0 1rem' : '0 2rem',
    };

    const headingStyle = {
        fontSize: isMobile ? '2rem' : '3.157rem',
        color: '#000',
        marginBottom: isMobile ? '2rem' : '5rem',
        fontWeight: 'bold',
        textAlign: 'left',
        lineHeight: '2.5rem'
    };

    const expectationItemStyle = {
        marginBottom: '2.5rem',
        position: 'relative', // Añadido para posicionar la barra
    };

    const expectationBarStyle = {
        position: 'absolute',
        left: '-10px', // Ajusta el valor según el espaciado que desees
        top: '0',
        bottom: '0',
        width: '2.5px', // Ancho de la barra
        backgroundColor: 'green', // Color de la barra
        transition: 'background-color 0.3s ease',
    };

    const expectationTitleStyle = {
        fontSize: isMobile ? '1.5rem' : '2.369rem',
        color: '#000',
        fontWeight: 'bold',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const expectationDescriptionStyle = {
        color: '#000',
        marginTop: '1rem',
        fontSize: isMobile ? '1rem' : '1.333rem',
        lineHeight: '1.6',
    };

    return (
        <Box style={containerStyle}>
            <Box style={contentContainerStyle}>
                <Heading style={headingStyle}>¿Qué puedes esperar con SheetSeller?</Heading>
                <Flex
                    direction={isMobile ? 'column' : 'row'}
                    style={{ gap: isMobile ? '2rem' : '0' }}
                >
                    <Box style={{ 
                        width: isMobile ? '100%' : '50%', 
                        paddingRight: isMobile ? '0' : '3rem'
                    }}>
                        {expectations.map((item, index) => (
                            <Box key={index} style={expectationItemStyle}>
                                {selectedExpectation === index && (
                                    <div style={expectationBarStyle} />
                                )}
                                <Button 
                                    onClick={() => setSelectedExpectation(index)}
                                    style={{
                                        ...expectationTitleStyle,
                                        background: selectedExpectation === index ? '#e0e0e0' : 'transparent',
                                        color: selectedExpectation === index ? 'green' : 'black',
                                        padding: isMobile ? '0.75rem' : '1rem',
                                        width: '100%',
                                        textAlign: 'left',
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: isMobile ? '60px' : '70px',
                                        justifyContent: 'flex-start',
                                        marginBottom: '0.5rem',
                                        lineHeight: '1'
                                    }}
                                >
                                    {item.title}
                                </Button>
                                <AnimatePresence>
                                    {selectedExpectation === index && (
                                        <motion.div
                                            initial={{ opacity: 0, height: 0 }}
                                            animate={{ opacity: 1, height: 'auto' }}
                                            exit={{ opacity: 0, height: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Text style={expectationDescriptionStyle}>
                                                {item.description}
                                            </Text>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </Box>
                        ))}
                    </Box>
                    <Box style={{ 
                        width: isMobile ? '100%' : '50%',
                        order: isMobile ? -1 : 0 // Mueve la imagen arriba en móvil
                    }}>
                        <AnimatePresence mode="wait">
                            {selectedExpectation !== null && (
                                <motion.img 
                                    key={selectedExpectation}
                                    src={expectations[selectedExpectation].image}
                                    alt={expectations[selectedExpectation].title}
                                    style={{ 
                                        maxWidth: '100%', 
                                        height: 'auto',
                                        marginBottom: isMobile ? '2rem' : '0'
                                    }}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                />
                            )}
                        </AnimatePresence>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default WhatToExpect;