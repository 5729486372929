import React, { useState, useEffect } from 'react';
import { Box, Container, Text, Heading, Button, Flex } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';
import './css/ReviewsHero.css';

const HeroContainer = styled(Box)`
  background-color: #0A2540;
  color: white;
  padding: ${props => props.isMobile ? '3rem 1rem' : '6rem 0'};
  position: relative;
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.isMobile ? 'center' : 'flex-start'};
  width: ${props => props.isMobile ? '100%' : '50%'};
  margin-left: ${props => props.isMobile ? '0' : '20%'};
  text-align: ${props => props.isMobile ? 'center' : 'left'};
`;

const SmallHeading = styled(Text)`
  color: #4CAF50;
  font-size: ${props => props.isMobile ? '1.4rem' : '1.777rem'};
  margin-bottom: 1rem;
`;

const MainHeading = styled(Heading)`
  color: white !important;
  font-size: ${props => props.isMobile ? '2.5rem' : '4.209rem'} !important;
  line-height: 1.2 !important;
  margin-bottom: 1.5rem !important;
  padding: ${props => props.isMobile ? '0 1rem' : '0'};
`;

const Subtitle = styled(Heading)`
  color: white !important;
  font-size: ${props => props.isMobile ? '1.5rem' : '2.369rem'} !important;
  line-height: 1.2 !important;
  margin-bottom: 1.5rem !important;
  font-weight: normal;
  padding: ${props => props.isMobile ? '0 1rem' : '0'};
`;

const Description = styled(Text)`
  color: white;
  font-size: ${props => props.isMobile ? '1.1rem' : '1.333rem'};
  line-height: 1.3 !important;
  margin-bottom: 2rem;
  text-align: ${props => props.isMobile ? 'left' : 'left'};
`;

const ButtonContainer = styled(Flex)`
  gap: ${props => props.isMobile ? '1rem' : '2rem'}; // Aumentamos la separación
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  align-items: center;
  justify-content: ${props => props.isMobile ? 'center' : 'start'};
  width: 100%;
  margin-top: 2rem;
`;


// Efecto de fondo con gradiente y overlay
const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at top right, rgba(0, 179, 126, 0.1), transparent 50%);
`;

const ReviewsHero = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);


  return (
    <HeroContainer isMobile={isMobile}>
      <StyledContainer size="3" isMobile={isMobile}>
        <SmallHeading isMobile={isMobile}>
          SheetSeller
        </SmallHeading>
        <MainHeading isMobile={isMobile}>
          Automatiza tu Negocio en Mercado Libre
        </MainHeading>
        <Subtitle isMobile={isMobile}>
          Ahorra +15 horas semanales y toma decisiones más inteligentes con datos en tiempo real
        </Subtitle>
        <Description isMobile={isMobile}>
          ⚡ 3 minutos para comenzar<br />
          📊 Actualización automática<br />
          🔄 Integrado con Google Sheets
        </Description>
        <ButtonContainer isMobile={isMobile}>
          <Link style={{ 'textDecoration': 'none', width: isMobile ? '100%' : 'auto' }} to={'/pricing'}>
            <Button
              size={isMobile ? "3" : "4"}
              className="custom-button-how"
              style={{
                width: isMobile ? '100%' : 'auto',
                height: isMobile ? '48px' : '56px',
                fontSize: isMobile ? '1.1rem' : '1.333rem',
              }}
            >
              ¡Comienza gratis ahora!
              <CursorArrowIcon style={{ marginLeft: '8px' }} />
            </Button>
          </Link>
          <Link to={'/demo'} style={{ textDecoration: 'none', width: isMobile ? '100%' : 'auto' }}>
            <Button
              size={isMobile ? "3" : "4"}
              className="outline-button-how"
              style={{
                width: isMobile ? '100%' : 'auto',
                height: isMobile ? '48px' : '56px',
                fontSize: isMobile ? '1.1rem' : '1.333rem'
              }}
            >
              Probar Demo
            </Button>
          </Link>
        </ButtonContainer>
      </StyledContainer>
    </HeroContainer>
  );
};

export default ReviewsHero;