import * as React from 'react';
import { Container, Heading, Text, Button, Flex, Box, Grid } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import TrustedCompanies from './TrustedCompanies';
import backgroundImage from '../../assets/images/hero_image.png';
import '../../styles/Hero.css'
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';

const Hero = () => (
  <Box className="hero-container">
    <Box
      className="hero-main-section"
      style={{
        backgroundColor: '#0A2540',
        position: 'relative',
      }}
    >
      <Container size="5" className="hero-container-inner">
        <Grid columns={{ initial: '1', md: '2' }} gap="6" align="center">
          <Flex direction="column" className="hero-content">
            <Text className="hero-subtitle">
              Conecta Mercado Libre con Google Sheets
            </Text>
            <Heading className="landing-hero-title">
              Transforma tus datos en decisiones inteligentes
            </Heading>
            <Text className="landing-hero-description">
              Sincroniza automáticamente tus métricas de Mercado Libre con Google Sheets en tiempo real 24/7
            </Text>
            <Flex className="hero-buttons">
              <ScrollLink to='HowItWorks' smooth={true}>
                <Button size="4" className="custom-button-hero">
                  Cómo Funciona
                  <CursorArrowIcon style={{ marginLeft: '8px' }} />
                </Button>
              </ScrollLink>
              <Link style={{ textDecoration: 'none' }} to={'/demo'}>
                <Button size="4" variant="outline" className="outline-button-hero">
                  Probar Demo
                </Button>
              </Link>
            </Flex>
          </Flex>
          <Box className="hero-image-container">
            <div className="hero-background-image" style={{ 
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})` 
            }}></div>
          </Box>
        </Grid>
      </Container>
    </Box>
    <Box py="4" style={{ backgroundColor: 'white' }}>
      <Container>
        <TrustedCompanies />
      </Container>
    </Box>
  </Box>
);

export default Hero;