import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Heading, Text, Button } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import './css/PricingHero.css'
import { Link as ScrollLink } from 'react-scroll';

const HeroContainer = styled(Container)`
  background: linear-gradient(45deg, #1E293B, #2D3748);
  color: white;
  padding: ${props => props.isMobile ? '2rem 1rem' : '4rem 0'};
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${props => props.isMobile ? '0.5rem' : '1rem'};
`;

const PricingHero = () => {
  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: typeof window !== 'undefined' ? window.innerWidth : 0,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }

      window.addEventListener('resize', handleResize);
      handleResize();
      
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  };

  const { width } = useWindowSize();
  const isMobile = width <= 768;

  return (
    <HeroContainer size="5" isMobile={isMobile}>
      <Heading style={{ 
        marginBottom: isMobile ? '2rem' : '4rem', 
        fontSize: isMobile ? '2.5rem' : '4.209rem',
        lineHeight: isMobile ? '2.75rem' : '4rem',
        padding: isMobile ? '0 1rem' : '0'
      }}>
        Automatiza tus métricas de Mercado Libre en tiempo real.
      </Heading>
      <Text style={{ 
        marginBottom: isMobile ? '1.5rem' : '3rem', 
        fontSize: isMobile ? '1.5rem' : '2.369rem',
        padding: isMobile ? '0 1rem' : '0'
      }}>
        Ahorra tiempo con nuestra integración directa a Google Sheets.
      </Text>
      <br />
      <Text style={{
        fontWeight: 'bold', 
        fontSize: isMobile ? '1.25rem' : '1.777rem',
        padding: isMobile ? '0 1rem' : '0'
      }}>
        Prueba gratis por 15 días y encuentra el plan perfecto para tu negocio.
      </Text>
      <ButtonContainer isMobile={isMobile}>
        <ScrollLink to='PricingPlans' smooth={true}>
          <Button 
            size={isMobile ? "2" : "3"} 
            className='custom-button-Pricinghero' 
            style={{
              marginTop: isMobile ? '0.5rem' : '1rem',
              fontSize: isMobile ? '1rem' : 'inherit',
              padding: isMobile ? '0 1.5rem' : undefined
            }}
          >
            Ver planes y precios
            <CursorArrowIcon style={{ 
              marginLeft: '8px',
              width: isMobile ? '16px' : '20px',
              height: isMobile ? '16px' : '20px'
            }} />
          </Button>
        </ScrollLink>
      </ButtonContainer>
    </HeroContainer>
  );
};

export default PricingHero;