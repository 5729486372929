import React from 'react';
import { Box, Heading, Text, Button, Flex, Container } from '@radix-ui/themes';
import './css/KeyFeatures.css';

const defaultFeatures = {
  title: "Características y Beneficios Principales",
  subtitle: "Control de Inventario",
  description: "Mantén un seguimiento detallado de tus ventas e inventario en tiempo real.",
  features: [
    { icon: "📊", text: "Datos en Tiempo Real" },
    { icon: "📈", text: "Análisis de Ventas" },
    { icon: "📦", text: "Control de Stock" },
    { icon: "🎯", text: "Predicciones de Inventario" }
  ]
};

const KeyFeatures = ({ features = defaultFeatures, image, url_template }) => {
  return (
    <Box className="key-features-container">
      <Container size="4">
        <Heading className="main-title">
          {features.title}
        </Heading>
        
        <Flex className="features-content">
          <Box className="features-image">
            <img 
              src={image || 'https://placehold.co/800x500'} 
              alt="Dashboard visualization" 
              className="dashboard-image"
            />
          </Box>

          <Box className="features-text">
            <Heading className="subtitle">
              {features.subtitle}
            </Heading>
            <Text className="description">
              {features.description}
            </Text>
            
            <Box className="features-cards">
              {features.features.map((feature, index) => (
                <Box key={index} className="feature-card">
                  <Box className="feature-header">
                    <span className="feature-emoji">{feature.icon}</span>
                    <Text className="feature-title">{feature.text}</Text>
                  </Box>
                  <Text className="feature-description">
                    {feature.description}
                  </Text>
                </Box>
              ))}
            </Box>

            <Button className="access-button-key" onClick={() => window.location.href = url_template}>
              Obtener Plantilla Gratis
            </Button>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default KeyFeatures;