import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Heading, Text, Flex, Button, Box } from '@radix-ui/themes';
import './css/PricingCalculator.css'
import PricingPlans from './PricingPlans';
import { Element } from 'react-scroll';

const CalculatorContainer = styled(Container)`
  background-color: #f5f5f5;
  padding: ${props => props.isMobile ? '3rem 1rem' : '6rem 0'};
`;

const SliderContainer = styled.div`
  width: 100%;
  max-width: ${props => props.isMobile ? '100%' : '700px'};
  margin: ${props => props.isMobile ? '1.5rem 0' : '3rem 0'};
  padding: ${props => props.isMobile ? '0 1rem' : '0'};
`;

const Slider = styled.input`
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background: #ddd;
  outline: none;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #02A724;
    cursor: pointer;
    transition: background .15s ease-in-out;
  }

  &::-moz-range-thumb {
    width: 28px;
    height: 28px;
    border: 0;
    border-radius: 50%;
    background: #02A724;
    cursor: pointer;
  }

  &::-webkit-slider-thumb:hover {
    background: #02A724;
  }
`;

const ResultBox = styled.div`
  background: #fff;
  padding: ${props => props.isMobile ? '2rem 1rem' : '4rem'};
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
  margin-top: ${props => props.isMobile ? '1.5rem' : '3rem'};
  width: 100%;
  max-width: ${props => props.isMobile ? '100%' : '700px'};
`;

const PricingCalculator = () => {
  const [orders, setOrders] = useState(500);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [plan, setPlan] = useState('');
  const [planID, setPlanID] = useState('');


  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: typeof window !== 'undefined' ? window.innerWidth : 0,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        });
      }

      window.addEventListener('resize', handleResize);
      handleResize();
      
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
  };

  const { width } = useWindowSize();
  const isMobile = width <= 768;



  const handleCheckout = () => {
    const baseUrl = process.env.REACT_APP_APPLICATION;
    const checkoutUrl = `${baseUrl}/suscription?plan=${planID}`;
    window.location.href = checkoutUrl;
  };



  const calculatePrice = (orderCount) => {
    let price;
    let newPlan = '';
    let planID = ''

    if (orderCount > 70000) {
      price = 14999;
      newPlan = 'Enterprise 100k';
      planID = 'ENT100_938';
    } else if (orderCount > 50000) {
      price = 10999;
      newPlan = 'Enterprise 70k';
      planID = 'ENT70_294';
    } else if (orderCount > 30000) {
      price = 8999;
      newPlan = 'Enterprise 50k';
      planID = 'ENT50_017';
    } else if (orderCount > 15000) {
      price = 5999;
      newPlan = 'Enterprise 30k';
      planID = 'ENT30_513'
    } else if (orderCount > 6000) {
      price = 3999;
      newPlan = 'Mega';
      planID = 'MEGA_681';
    } else if (orderCount > 1000) {
      price = 1999;
      newPlan = 'Pro';
      planID = 'PRO_824';
    } else if (orderCount > 500) {
      price = 999;
      newPlan = 'Básico';
      planID = 'BAS_092';
    } else {
      price = 699;
      newPlan = 'Principiante';
      planID = 'PRIN_972'
    }

    setEstimatedPrice(price);
    setPlan(newPlan);
    setPlanID(planID)
  };

  // Calcula el precio inicial y cuando cambia el valor del slider
  useEffect(() => {
    calculatePrice(orders);
  }, [orders]);

  // Función para manejar la selección de un plan
  const handlePlanSelection = (orderCount) => {
    setOrders(orderCount)
  }

  return (
    <>
      <CalculatorContainer size="3" isMobile={isMobile}>
        <Heading 
          align="center" 
          style={{ 
            marginBottom: isMobile ? '1.5rem' : '3rem', 
            fontSize: isMobile ? '2rem' : '3.157rem',
            padding: isMobile ? '0 1rem' : '0'
          }}
        > 
          Estima tu precio mensual
        </Heading>
        <Flex direction="column" align="center" gap={isMobile ? "3" : "5"}>
          <SliderContainer isMobile={isMobile}>
            <Text 
              size="5" 
              style={{ 
                marginBottom: isMobile ? '1rem' : '1.5rem', 
                fontSize: isMobile ? '1.2rem' : '1.777rem'
              }}
            >
              Número de órdenes al mes: {orders}
            </Text>
            <Slider
              type="range"
              min="100"
              max="100000"
              value={orders}
              onChange={(e) => setOrders(Number(e.target.value))}
            />
          </SliderContainer>
          
          <ResultBox isMobile={isMobile}>
            <Box style={{ marginBottom: isMobile ? '1rem' : '2rem' }}>
              <Text style={{ 
                color: '#02A724', 
                fontWeight: 'bold', 
                fontSize: isMobile ? '1.8rem' : '2.369rem'
              }}>
                ${estimatedPrice} 
              </Text>
              <Text 
                size="4" 
                style={{ 
                  color: '#666', 
                  fontSize: isMobile ? '1.2rem' : '1.777rem'
                }}
              >
                por mes
              </Text>
            </Box>
            
            <Box style={{ marginBottom: isMobile ? '1rem' : '2rem' }}>
              <Text style={{ 
                color: '#333', 
                fontSize: isMobile ? '1.1rem' : '1.333rem'
              }}>
                Plan Recomendado: {plan}
              </Text>
            </Box>
            
            <Box>
              <Text style={{ 
                color: '#999', 
                fontSize: isMobile ? '0.875rem' : '1rem'
              }}>
                Todas las cifras son estimadas. Los valores reales pueden variar.
              </Text>
            </Box>
          </ResultBox>

          <Button 
            size={isMobile ? "3" : "4"}
            className='custom-button-PricingCal'
            style={{
              height: isMobile ? '48px !important' : '56px !important',
              fontSize: isMobile ? '1.1rem !important' : '1.333rem !important',
              padding: isMobile ? '0 1.5rem !important' : '0 2.5rem !important'
            }}
            onClick={handleCheckout}
          >
            Ir al pago →
          </Button>
          <Text style={{
            fontSize: isMobile ? '0.875rem' : '1rem',
            padding: isMobile ? '0 1rem' : '0',
            textAlign: 'center'
          }}>
            Prueba cualquiera de nuestros planes gratis por 15 días. Sin compromiso.
          </Text>
        </Flex>
      </CalculatorContainer>
      <Element name='PricingPlans'>
        <PricingPlans onPlanSelect={handlePlanSelection}/>
      </Element>
    </>
  );
};

export default PricingCalculator;