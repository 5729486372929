import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Heading } from '@radix-ui/themes';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { keyframes } from "styled-components";

const slideDown = keyframes`
  from {
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    height: var(--radix-accordion-content-height);
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
    transform: translateY(0);
  }
  to {
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
`;


const StyledAccordion = styled(AccordionPrimitive.Root)`
  width: 100%;
`;

const StyledItem = styled(AccordionPrimitive.Item)`
  margin-top: 1px;
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledHeader = styled(AccordionPrimitive.Header)`
  all: unset;
  display: flex;
`;

const StyledTrigger = styled(AccordionPrimitive.Trigger)`
  all: unset;
  font-family: inherit;
  background-color: transparent;
  padding: ${props => props.isMobile ? '15px' : '20px'};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${props => props.isMobile ? '1.1rem' : '1.777rem'};
  line-height: 1.2;
  color: #333;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #dcdcdc;
  }

  & > svg {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    width: ${props => props.isMobile ? '16px' : '20px'};
    height: ${props => props.isMobile ? '16px' : '20px'};
  }

  &[data-state='open'] > svg {
    transform: rotate(180deg);
  }

  &[data-state='open']::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #4CAF50;
    transition: opacity 300ms ease;
  }
`;

const StyledContent = styled(AccordionPrimitive.Content)`
  overflow: hidden;
  font-size: ${props => props.isMobile ? '1rem' : '1.333rem'};
  color: #333;
  background-color: #f5f5f5;
  padding: ${props => props.isMobile ? '10px 15px' : '15px 20px'};
  
  > div {
    padding: ${props => props.isMobile ? '10px 15px' : '15px 20px'};
  }

  &[data-state='open'] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  line-height: 1.4;
  will-change: height;
  transition: background-color 200ms ease;

  &:hover {
    background-color: #f5f5f5;
  }

  p {
    margin: 0;
    color: #666;
  }
`;

const FAQContainer = styled(Container)`
  padding: ${props => props.isMobile ? '2rem 1rem' : '4rem 0'};
  background-color: #f7f7f7;
`;

const AccordionItem = ({ value, question, answer, isMobile }) => (
  <StyledItem value={value}>
    <StyledHeader>
      <StyledTrigger isMobile={isMobile}>
        {question}
        <ChevronDownIcon />
      </StyledTrigger>
    </StyledHeader>
    <StyledContent isMobile={isMobile}>{answer}</StyledContent>
  </StyledItem>
);

const PricingFAQ = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);


  return (
    <FAQContainer size="3" isMobile={isMobile}>
      <Heading 
        align="center" 
        style={{ 
          marginBottom: isMobile ? '2rem' : '5rem', 
          color: '#111', 
          fontSize: isMobile ? '1.8rem' : '2.369rem',
          padding: isMobile ? '0 1rem' : '0'
        }}
      >
        Preguntas Frecuentes
      </Heading>
    <StyledAccordion type="single" collapsible>
      <AccordionItem
        value="item-1"
        question="🆓 ¿Cómo funciona la prueba gratuita?"
        answer="Te ofrecemos 15 días de prueba gratuita para que explores todas las funciones de SheetSeller sin compromiso. ¡Es la mejor forma de descubrir cómo puede ayudarte a gestionar tus ventas! 🚀"
        isMobile={isMobile}
      />
      <AccordionItem
        value="item-2"
        question="🔄 ¿Puedo cambiar de plan en cualquier momento?"
        answer="¡Por supuesto! Puedes actualizar o cambiar tu plan en cualquier momento desde tu perfil. Los cambios se reflejarán automáticamente en tu próxima factura. ✅"
        isMobile={isMobile}
      />
      <AccordionItem
        value="item-3"
        question="📧 ¿Es necesario suscribirme utilizando el correo de mi cuenta de Mercado Libre?"
        answer="¡No es necesario! 😊 Una vez que te suscribas a SheetSeller, tendrás acceso a nuestra plataforma de administración, donde podrás vincular todas las cuentas de Mercado Libre que desees, sin importar el correo que uses para la suscripción. 🔗"
        isMobile={isMobile}
      />
      <AccordionItem
        value="item-4"
        question="📧 ¿Puedo suscribirme sin tener una cuenta de Mercado Pago?"
        answer="¡Claro que sí! No necesitas una cuenta de Mercado Pago para suscribirte. Solo ingresa un correo electrónico válido, y ahí recibirás los cobros mensuales. Es fácil y rápido. ⚡"
        isMobile={isMobile}
      />
      <AccordionItem
        value="item-5"
        question="💳 ¿Qué métodos de pago aceptan?"
        answer="Aceptamos todos los métodos de pago disponibles en Mercado Pago, incluyendo tarjetas de crédito, débito, PayPal y el saldo disponible en tu cuenta de Mercado Pago. ¡Elige el que más te convenga! 🌟"
        isMobile={isMobile}
      />
      <AccordionItem
        value="item-6"
        question="🔒 ¿Es seguro ingresar mis datos bancarios?"
        answer="¡Absolutamente! La seguridad es nuestra prioridad. El formulario de pago es proporcionado por Mercado Pago, una plataforma confiable que protege tus datos con los más altos estándares de seguridad. Nosotros no almacenamos tu información bancaria. 🛡️"
        isMobile={isMobile}
      />
      <AccordionItem
        value="item-7"
        question="❌ Si no tengo una cuenta de Mercado Pago, ¿cómo cancelo la suscripción?"
        answer="Desde tu perfil en nuestra plataforma, puedes cancelar tu suscripción en cualquier momento. Esta acción se comunica directamente con Mercado Pago para finalizar tu plan. Sin complicaciones. 🛑"
        isMobile={isMobile}
      />
    </StyledAccordion>
  </FAQContainer>
);
};

export default PricingFAQ;