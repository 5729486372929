import * as React from 'react';
import { Flex, Button, Text } from '@radix-ui/themes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SparklesIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import '../../styles/Header.css'

const Header = () => {
  const navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const location = useLocation();

  const buttonStyle = (buttonName) => ({
    color: 'white',
    position: 'relative',
    fontSize: '1rem',
    padding: '12px 12px',
    transition: 'all 0.3s ease',
    backgroundColor: hoveredButton === buttonName ? 'rgba(255,255,255,0.1)' : 'transparent',
    cursor: 'pointer',
    borderRadius: '4px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      width: (hoveredButton === buttonName || location.pathname === `/${buttonName.toLowerCase().replace(/\s+/g, '-')}`) ? '100%' : '0',
      height: '2px',
      backgroundColor: '#4CAF50',
      transition: 'all 0.3s ease',
      transform: 'translateX(-50%)'
    }
  });

  const navItems = [
    { name: 'Inicio', path: '/' },
    { name: 'Plantillas Gratis', path: '/templates' },
    { name: 'Precios', path: '/pricing' },
    { name: 'Casos de éxito', path: '/casos-de-exito' },
    { name: 'Docs', path: '/docs/' }
  ];

  const handleDocsClick = () => {
    const newPath = '/docs';
    window.location.href = newPath;
  };

  return (
    <Flex justify="between" align="center" py="2" px="6" className="header-main" style={{
      backgroundColor: '#1E293B',
      color: 'white',
      position: 'sticky',
      top: 0,
      zIndex: 1000
    }}>
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Flex align="center" gap="2">
          <div style={{
            backgroundColor: '#4CAF50',
            padding: '8px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Text style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'white' }}>SS</Text>
          </div>
          <Text style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>SheetSeller</Text>
        </Flex>
      </Link>

      {/* Botón hamburguesa solo visible en móvil */}
      <Button 
        className="mobile-menu-button"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        variant="ghost"
      >
        {isMenuOpen ? 
          <XMarkIcon style={{ width: '24px', height: '24px', color: 'white' }} /> : 
          <Bars3Icon style={{ width: '24px', height: '24px', color: 'white' }} />
        }
      </Button>

      {/* Menú de navegación */}
      <Flex gap="4" className={`desktop-menu ${isMenuOpen ? 'mobile-menu-open' : ''}`}>
        {navItems.map((item) => (
          item.name === 'Docs' ? (
            <Button
              key={item.name}
              variant="ghost"
              style={buttonStyle(item.name)}
              onClick={() => {
                handleDocsClick();
                setIsMenuOpen(false); // Cierra el menú después del clic
              }}
              onMouseEnter={() => setHoveredButton(item.name)}
              onMouseLeave={() => setHoveredButton(null)}
            >
              {item.name}
            </Button>
          ) : (
            <Link key={item.name} to={item.path} style={{ textDecoration: 'none' }} onClick={() => setIsMenuOpen(false)}>
              <Button
                variant="ghost"
                style={buttonStyle(item.name)}
                onMouseEnter={() => setHoveredButton(item.name)}
                onMouseLeave={() => setHoveredButton(null)}
              >
                {item.name}
              </Button>
            </Link>
          )
        ))}

        <Link 
          to="#" 
          style={{ textDecoration: 'none' }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = process.env.REACT_APP_BOT;
            setIsMenuOpen(false);
          }}
        >
          <Button variant="ghost" className="chat-button">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <ChatBubbleBottomCenterTextIcon style={{ width: '24px', height: '24px', color: '#fff' }} />
              <SparklesIcon className="sparkle-icon" />
            </div>
            <span>FormuBot IA</span>
          </Button>
        </Link>

        <Button className='button-demo' onClick={() => {
          navigate("/demo");
          setIsMenuOpen(false); // Cierra el menú después del clic
        }}>
          Probar Demo
        </Button>
      </Flex>
    </Flex>
  );
};

export default Header;