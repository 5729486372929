import React from 'react';
import { Theme, Box, Container } from '@radix-ui/themes';
import Hero from '../components/templates/Hero';
import TemplatesGrid from '../components/templates/TemplatesGrid';
//import GettingStarted from '../components/templates/GettingStarted';
//import GameChanger from '../components/templates/GameChanger';
import TrustedCompanies from '../components/common/TrustedCompanies'
//import PowerfulInsights from '../components/templates/PowerfulInsights';
import HowItWorks from '../components/common/HowItWorks';

const Templates = () => {
  return (
    <div className="templates-page">
      <Hero /> {/* Sacamos Hero del Theme wrapper */}
      <Box py="4" style={{ backgroundColor: 'white' }}>
        <Container>
          <TrustedCompanies />
        </Container>
      </Box>
      <div id="templates-grid">
        <TemplatesGrid />
      </div>
      <Theme>
        <HowItWorks />
        {/* <GameChanger />
        <PowerfulInsights /> */}
      </Theme>
    </div>
  );
};

export default Templates;