import React from 'react';
import './css/Hero.css';
import { useNavigate } from 'react-router-dom';
import template_start from '../../assets/images/templat/hero_image.png';

const Hero = () => {
  const navigate = useNavigate();

  const scrollToTemplates = () => {
    const element = document.getElementById('templates-grid');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="hero">
      <div className="hero__inner">
        <div className="hero__content">
          <h1 className="hero__title">
            Plantillas de Google Sheets + Mercado Libre | 100% Gratis
          </h1>
          <p className="hero__description">
            Sincroniza y analiza tus ventas en tiempo real con plantillas pre-configuradas para Sheetseller. Datos actualizados automáticamente.
          </p>
          <div className="hero__buttons">
            <button 
              className="hero__button"
              onClick={scrollToTemplates}
              aria-label="Ver plantillas disponibles"
            >
              Ver plantillas
            </button>
            <button 
              className="hero__button"
              onClick={() => navigate("/demo")}
              aria-label="Probar demostración"
            >
              Probar demo
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;