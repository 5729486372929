import React, { useState, useEffect } from 'react';
import Hero from '../components/common/Hero';
import Features from '../components/common/Features';
import Testimonials from '../components/common/Testimonials';
import HowItWorks from '../components/common/HowItWorks';
import FAQ from '../components/common/FAQ';
import OldWay from '../components/common/OldWay';
import NewWay from '../components/common/NewWay';
import WhatToExpect from '../components/common/WhatToExpect';
import { Element } from 'react-scroll';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import '../styles/Landing.css';

const LandingPage = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const slides = [
    { component: <OldWay />, title: "Antes" },
    { component: <NewWay />, title: "Ahora" }
  ];

  const toggleSlide = () => {
    setActiveSlide((prev) => (prev === 0 ? 1 : 0));
  };

  return (
    <div>
      <Hero />
      
      {isMobile ? (
        <div className="ways-slider">
          <div className="toggle-container">
            <div className="toggle-slider" onClick={toggleSlide}>
              <div className={`toggle-button ${activeSlide === 1 ? 'right' : ''}`} />
              <div className="toggle-labels">
                <span className={activeSlide === 0 ? 'active' : ''}>Antes</span>
                <span className={activeSlide === 1 ? 'active' : ''}>Ahora</span>
              </div>
            </div>
          </div>
          <div className="slider-content">
            {slides[activeSlide].component}
          </div>
        </div>
      ) : (
        <>
          <OldWay />
          <NewWay />
        </>
      )}

      <Element name='HowItWorks'>
        <HowItWorks />
      </Element>
      <Features />
      <Testimonials />
      <WhatToExpect />
      <FAQ />
    </div>
  );
};

export default LandingPage;