// Features.jsx
import * as React from 'react';
import { Container, Heading, Grid, Box, Text, Flex } from '@radix-ui/themes';
import '../../styles/Features.css'

const Feature = ({ title, description, icon }) => (
  <Box style={{
    backgroundColor: '#2D3748',
    padding: '2rem',
    borderRadius: '8px',
    color: 'white'
  }}>
    {icon}
    <Heading style={{ marginBottom: '1rem', fontSize: '1.777rem' }}>{title}</Heading>
    <Text style={{ fontSize: '1.333rem' }}>{description}</Text>
  </Box>
);

const SyncBanner = () => (
  <Box className="sync-banner" style={{
    background: 'linear-gradient(135deg, #50D5B7, #067D68)',
    borderRadius: '12px',
    padding: '4rem',
    marginTop: '3rem',
    color: 'white',
    textAlign: 'center'
  }}>
    <Flex direction="column" align="center">
      <Text size="7" style={{ marginBottom: '0.5rem', color: '#FFD700' }}>
        ★★★★★
      </Text>
      <Heading style={{ marginBottom: '2rem', fontSize: '3.157rem', lineHeight: '3.5rem' }}>
        Más de $400M en ventas sincronizadas en Mercado Libre
      </Heading>
      <Text style={{ fontSize: '1.777rem' }}>
        Empresas de todos los tamaños confían en nuestra tecnología para obtener datos precisos y actualizados a través de nuestro complemento de Google Sheets.
      </Text>
    </Flex>
  </Box>
);

const Features = () => {
  const [activeFeature, setActiveFeature] = React.useState(0);
  const [isMobile, setIsMobile] = React.useState(false);

  const features = [
    {
      icon: <span style={{ fontSize: '2rem', display: 'inline-block', marginBottom: '1rem' }}>📊</span>,
      title: "Datos en tiempo real",
      description: "Sincroniza automáticamente tus datos de Mercado Libre para tomar decisiones basadas en información actualizada al instante."
    },
    {
      icon: <span style={{ fontSize: '2rem', display: 'inline-block', marginBottom: '1rem' }}>📋</span>,
      title: "Informes personalizables",
      description: "Crea reportes a medida que se adapten a tus necesidades y analiza el rendimiento de tus ventas como nunca antes."
    },
    {
      icon: <span style={{ fontSize: '2rem', display: 'inline-block', marginBottom: '1rem' }}>📦</span>,
      title: "Gestión de inventario simplificada",
      description: "Monitorea tu inventario en Mercado Libre en tiempo real y evita problemas de stock."
    },
    {
      icon: <span style={{ fontSize: '2rem', display: 'inline-block', marginBottom: '1rem' }}>💰</span>,
      title: "Análisis financiero claro",
      description: "Rastrea tus ganancias, pérdidas y métricas clave con facilidad para mantener el control de tu negocio."
    }
  ];

  React.useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  React.useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setActiveFeature((prev) => (prev === features.length - 1 ? 0 : prev + 1));
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isMobile]);

  return (
    <Container size="6" py="9" style={{
      backgroundColor: '#1E293B',
      color: 'white'
    }}>
      <Heading className='title-features'>
        Por Qué Elegirnos
      </Heading>
      {isMobile ? (
        <div className="mobile-features">
          <div 
            className="mobile-features-track"
            style={{ transform: `translateX(-${activeFeature * 100}%)` }}
          >
            {features.map((feature, index) => (
              <div key={index} className="mobile-feature-slide">
                <Feature
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                />
              </div>
            ))}
          </div>
          <div className="mobile-dots">
            {features.map((_, index) => (
              <button
                key={index}
                className={`mobile-dot ${index === activeFeature ? 'active' : ''}`}
                onClick={() => setActiveFeature(index)}
              />
            ))}
          </div>
        </div>
      ) : (
        <Grid columns="2" gap="6">
          {features.map((feature, index) => (
            <Feature
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </Grid>
      )}
      <SyncBanner />
    </Container>
  );
};

export default Features;