import React from 'react';
import { Box, Flex, Text, Heading, Container } from '@radix-ui/themes';
import relaxed from '../../assets/images/relaxed.png';

const NewWay = () => {
  const items = [
    "Datos siempre actualizados en tiempo real",
    "Acelera tu flujo de trabajo y ahorra tiempo",
    "Información precisa y confiable directamente de Mercado Libre",
    "Genera reportes personalizados en segundos",
    "Toma decisiones rápidas y basadas en datos actualizados",
  ];

  return (
    <Container size="3" className="oldway-container">
      <Box className="oldway-box">
        <Flex className="oldway-flex-container reverse">
          <Box className="oldway-content">
            <Text className='subtitle-way'>
              Después de cambiar a SheetSeller
            </Text>
            <Heading className='title-newway'>
              La nueva forma
            </Heading>
            <Box as="ul" className="oldway-list">
              {items.map((item, index) => (
                <Flex as="li" key={index} className="oldway-list-item">
                  <Text className="oldway-x-mark check">
                    ✓
                  </Text>
                  <Text className='points-ways'>
                    {item}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Box>
          <Box className="oldway-image-container">
            <img src={relaxed} alt="La nueva forma" className="oldway-image" />
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default NewWay;